import * as React from "react"

import { Icon } from '@iconify/react'

import { WrapInput, WrapInput2, InputNum, InputNum2, InputBtn, InputBtn2 } from './numericInput.styles'

export const NumericInput = ({ onIncrement, onDecrement, className, disabled, ...props }) => {
  return (
    <WrapInput>
      <InputNum disabled={disabled} type="numeric" className={className} {...props} />
      <InputBtn className="increment" disabled={disabled} aria-label="Increment" onClick={onIncrement}>
        <Icon icon="ic:round-arrow-drop-up" />
      </InputBtn>
      <InputBtn className="decrement" disabled={disabled} aria-label="Decrement" onClick={onDecrement}>
        <Icon icon="ic:round-arrow-drop-down" />
      </InputBtn>
    </WrapInput>
  )
}

export const NumericInput2 = ({ onIncrement, onDecrement, className, disabled, ...props }) => {
  return (
    <WrapInput2>
      <InputBtn2 className="decrement" disabled={disabled} aria-label="Decrement" onClick={onDecrement}>
        <Icon icon="ion:remove-circle" />
      </InputBtn2>
      <InputNum2 disabled={disabled} type="numeric" className={className} {...props} />
      <InputBtn2 className="increment" disabled={disabled} aria-label="Increment" onClick={onIncrement}>
        <Icon icon="ion:add-circle" />
      </InputBtn2>
    </WrapInput2>
  )
}
