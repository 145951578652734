import styled from 'styled-components'
import { Link } from "gatsby"
import {Card, Button} from 'react-bootstrap'

export const CardLineItems = styled(Card)`
    background: ${({theme}) => theme.cardBg};
    display: flex;
    flex-direction: row;
    border-radius: 15px;

    .LineItemImg{
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }
`
export const ProdTitle = styled(Link)`
    font-size: 16px;
    color: ${({theme}) => theme.text};

    &:hover{
        color: ${({theme}) => theme.text};
        opacity: 0.8;
    }
`

export const RemoveLineItem = styled(Button)`
    border-radius: 5px;
    padding: 4px 6px;
    margin-top: 1rem;
    background: ${({theme}) => theme.btn.default};
    color: ${({theme}) => theme.text};
    border: none;
`

export const SubTitle = styled.span`
    font-size: 12px;
    opacity: 0.8;
`