import * as React from "react"
import debounce from "lodash.debounce"
import { StoreContext } from "../context/store-context"
import { formatPrice } from "../utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { InlineIcon } from '@iconify/react';
import { NumericInput2 } from "./input/numeric-input"
import { ProdTitle, CardLineItems, RemoveLineItem, SubTitle } from "./line-item.styles"

export function LineItem({ item }) {
  const { removeLineItem, checkout, updateLineItem, loading } = React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(item.quantity)

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  )

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 120,
        height: 140,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    
      <CardLineItems className="mb-3">
        <CardLineItems.Body>
          <div className="d-flex flex-row flex-md-row align-items-start">
            
            {image && <GatsbyImage className="LineItemImg" key={variantImage.src} image={image} alt={variantImage.altText ?? item.variant.title} /> }

            <div className="d-flex flex-column w-100 ms-3">
              <ProdTitle to={`/products/${item.variant.product.handle}`}>{item.title}</ProdTitle>
              <span>{item.variant.title === "Default Title" ? "" : item.variant.title}</span>

              <div className="d-flex flex-row align-items-end mt-3">

                <div className="d-flex flex-column">
                  <SubTitle className="d-flex d-md-none">Per Piece: {price}</SubTitle>
                  <NumericInput2 disabled={loading} value={quantity} aria-label="Quantity"
                    onIncrement={doIncrement} onDecrement={doDecrement} onChange={(e) => handleQuantityChange(e.currentTarget.value)} />
                </div>
                
                <div className="ms-3 d-none d-md-flex flex-column">
                  <SubTitle>Per Piece</SubTitle>
                  <span>x {price}</span>
                </div>

                <div className="ms-auto ms-md-auto d-flex flex-column">
                  <SubTitle>Total</SubTitle>
                  <span>{subtotal}</span>
                </div>

              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start">
            <RemoveLineItem variant="warning" onClick={handleRemove}><InlineIcon icon="ion:remove-circle" className="me-1" /> Remove </RemoveLineItem>
          </div>
        </CardLineItems.Body>
      </CardLineItems>
  )
}
