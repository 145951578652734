import styled from 'styled-components'
import { Button } from 'react-bootstrap'

export const RowTitle = styled.h1`
    font-size: 1.75rem;
`

export const RowSubTitle = styled.h2`
    font-size: 1.4rem;
    margin-bottom: 1.4rem;
`

export const CalcDiv = styled.div`
    background: ${({theme}) => theme.cardBg};
    padding: 1em 1em 1em;
    border-radius: 15px;
`

export const FinalTitle = styled.span`
    font-size: 18px;
    opacity: 0.8;
`

export const FinalPrice = styled.span`
    font-size: 18px;
`

export const CheckoutBtn = styled(Button)`
    width: 100%;
`