import styled from 'styled-components'

export const WrapInput = styled.div`
    display: inline-grid;
    grid-template-columns: 1fr min-content;
    grid-template-areas: "input increment" "input decrement";
    overflow: hidden;
`

export const InputNum = styled.input`
    grid-area: input;
    padding: 0.8rem 0.2rem 0.8rem 0.8rem;
    align-self: stretch;
    width: 6ch;
    width: 100px;
    background: ${({theme}) => theme.inputBg.elev3};
    border: ${({theme}) => theme.body};
    border-style: solid;
    border-width: 0 2px 0 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: var(--medium);
    color: var(--input-text);
    
    &:disabled{
        color: var(--input-text-disabled);
    }
`

export const InputBtn = styled.button`
    background: ${({theme}) => theme.inputBg.elev3};
    color: ${({theme}) => theme.text};
    padding: 0 0.5rem;
    // display: grid;
    place-items: center;

    &:focus-visible{
        background: #000;
        border: none;
    }

    &:hover{
        background-color: ${({theme}) => theme.accent.primary.color};
        color: ${({theme}) => theme.accent.primary.text};

        &:disabled{
            background: none;
            color: var(--input-text-disabled);
        }
    }

    &.increment{
        border-top-right-radius: 10px;
        grid-area: increment;
        border-bottom: 1px ${({theme}) => theme.body} solid;
    }

    &.decrement{
        border-bottom-right-radius: 10px;
        grid-area: decrement;
        border-top: 1px ${({theme}) => theme.body} solid;
    }

`

export const WrapInput2 = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
`
export const InputBtn2 = styled.button`
    background: ${({theme}) => theme.inputBg.elev3};
    color: ${({theme}) => theme.text};
    border-radius: 5px;
`

export const InputNum2 = styled.input`
    padding: 0.4rem 0.2rem 0.4rem 0.8rem;
    margin: 0 5px;
    align-self: stretch;
    width: 3ch;
    width: 40px;
    background: ${({theme}) => theme.inputBg.elev3};
    color: ${({theme}) => theme.text};
    border-radius: 5px;
    
    &:disabled{
        color: var(--input-text-disabled);
    }
`