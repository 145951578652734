import * as React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
// import Layout  from "../components/layout"
import { StoreContext } from "../context/store-context"
import { LineItem } from "../components/line-item"
import { formatPrice } from "../utils/format-price"
import {
  emptyStateContainer,
  emptyStateHeading,
  emptyStateLink,
} from "./cart.module.css"
import { RowTitle, RowSubTitle, FinalTitle, FinalPrice, CalcDiv, CheckoutBtn } from '../styles/pages/cart.styles'
import { SubTitle } from "../components/line-item.styles"

export default function CartPage() {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  return (
    <Container fluid>
      {emptyCart ? (
        <div className={emptyStateContainer}>
          <h1 className={emptyStateHeading}>Your cart is empty</h1>
          <p>
            Looks like you haven’t found anything yet. We understand that
            sometimes it’s hard to chose — maybe this helps:
          </p>
          <Link to="/search?s=BEST_SELLING" className={emptyStateLink}>
            View trending products
          </Link>
        </div>
      ) : (
          <Row className="px-3">
              <RowTitle className="mb-4">Your cart</RowTitle>

            <Col sm={12} lg={6} className="px-2 px-md-2">
              <Row xs={1} md={1}>
                <Col>
                {checkout.lineItems.map((item) => (
                    <LineItem item={item} key={item.id} />
                  ))}
                </Col>
              </Row>
            </Col>
            <Col sm={12} lg={4} className="ms-auto">
              <CalcDiv>
                <RowSubTitle>Pricing Details</RowSubTitle>

                <div className="my-3">
                  <div className="d-flex flex-row justify-content-between">
                    <SubTitle>Sub Total</SubTitle>
                    <span>{formatPrice( checkout.subtotalPriceV2.currencyCode, checkout.subtotalPriceV2.amount )}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <SubTitle>Tax</SubTitle>
                    {formatPrice( checkout.totalTaxV2.currencyCode, checkout.totalTaxV2.amount )}
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <SubTitle>Shipping</SubTitle>
                    <span>Calculated at Next Page</span>
                  </div>
                  <div className="d-flex flex-row justify-content-between mt-2">
                    <FinalTitle>Final</FinalTitle>
                    <FinalPrice>{formatPrice( checkout.totalPriceV2.currencyCode, checkout.totalPriceV2.amount )}</FinalPrice>
                  </div>
                </div>
                
                <CheckoutBtn variant="success" onClick={handleCheckout} disabled={loading}>Checkout</CheckoutBtn>
              </CalcDiv>
            </Col>
          </Row>
      )}
    </Container>
  )
}
